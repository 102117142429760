import React from 'react';
import {
  Routes,
  Route
} from 'react-router-dom';

import IndexPage from './pages/Index.js';
import PortfolioPage from './pages/Portfolio.js';
import OmossPage from './pages/Omoss.js';
import FaqPage from './pages/Faq.js';
import BackupRobinPage from './pages/Backup-robin.js';

function App() {
  return (
    <Routes>
      <Route path="/" element={<IndexPage />} />
         
      <Route path="/portfolio" element={<PortfolioPage />} />
         
      <Route path="/omoss" element={<OmossPage />} />
         
      <Route path="/faq" element={<FaqPage />} />
         
      <Route path="/backuprobin" element={<BackupRobinPage />} />
    </Routes>
  );
}

export default App;
